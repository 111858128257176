import React, { useState, useRef, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';

const AudioComponent = ({ src, type }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const audioRef = useRef(null);
  const playPromiseRef = useRef(null);

  useEffect(() => {
    const audio = audioRef.current;
    audio.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration);
    });

    audio.addEventListener('timeupdate', () => {
      setCurrentTime(audio.currentTime);
    });

    audio.addEventListener('ended', () => {
      setIsPlaying(false);
    });

    return () => {
      audio.removeEventListener('loadedmetadata', () => {});
      audio.removeEventListener('timeupdate', () => {});
      audio.removeEventListener('ended', () => {});
    };
  }, []);

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handlePlayPause = async () => {
    try {
      if (isPlaying) {
        if (playPromiseRef.current) {
          await playPromiseRef.current;
        }
        audioRef.current.pause();
        setIsPlaying(false);
      } else {
        playPromiseRef.current = audioRef.current.play();
        await playPromiseRef.current;
        setIsPlaying(true);
      }
    } catch (error) {
      console.error('Playback error:', error);
      setIsPlaying(false);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: '#f5f5f5',
        borderRadius: '8px',
        p: 2,
        width: '100%',
      }}
    >
      <audio
        ref={audioRef}
        src={src}
        type={type}
        style={{ display: 'none' }}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
        <IconButton
          onClick={handlePlayPause}
          sx={{
            bgcolor: '#4caf50',
            color: '#fff',
            '&:hover': {
              bgcolor: '#357a38',
            },
            width: 48,
            height: 48,
          }}
        >
          <i className={`fas ${isPlaying ? 'fa-pause' : 'fa-play'}`} />
        </IconButton>

        <Box sx={{ flex: 1 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              mt: 1,
              px: 1,
            }}
          >
            <Typography variant="caption" color="textSecondary">
              {formatTime(currentTime)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AudioComponent;