// FillInBlanks.jsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import RefreshIcon from "@mui/icons-material/Refresh";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import CloseIcon from "@mui/icons-material/Close";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function FillInBlanksSelect() {
  const navigate = useNavigate();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [answers, setAnswers] = useState({
    blank1: "",
    blank2: "",
    blank3: "",
    blank4: "",
  });

  const handleAnswerChange = (blank) => (event) => {
    setAnswers({
      ...answers,
      [blank]: event.target.value,
    });
  };


  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            R/W Fill in Blanks
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>4/650</Typography>
          <Box sx={{ display: "flex", gap: 0.5 }}>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
            >
              <i className="fas fa-search" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-rotate" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-chevron-right" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 3, pt: 4, pb: "80px" }}>
        <Typography
          sx={{
            color: "rgba(255,255,255,0.7)",
            fontStyle: "italic",
            fontSize: "15px",
            mb: 3,
          }}
        >
          Below is a text with blanks. Click on each blank, a list of choices
          will appear. Select the appropriate answer choice for each blank.
        </Typography>

        {/* Text Content with Blanks */}
        <Box
          sx={{
            fontSize: "10px",
            color: "rgba(255,255,255,0.9)",
          }}
        >
          <Typography component="div" sx={{ display: "inline" }}>
            In an effort to increase applications and
            <FormControl
              size="small"
              sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
            >
              <Select
                value={answers.blank1}
                onChange={handleAnswerChange("blank1")}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  height: "25px !important",
                  borderRadius: "4px !important",
                  width: "auto !important",
                  backgroundColor: "transparent",
                  "& .MuiSelect-select": {
                    color: "#000",
                    padding: "0px 0px 0px 0px !important",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    minWidth: "0px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em style={{ color: "#000" }}>Select</em>
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="heightened">
                  heightened
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="increased">
                  increased
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="intensified">
                  intensified
                </MenuItem>
              </Select>
            </FormControl>
            competition for the brightest students from other graduate programs,
            including law and public policy, top US business schools are hiring
            younger, less
            <FormControl
              size="small"
              sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
            >
              <Select
                value={answers.blank2}
                onChange={handleAnswerChange("blank2")}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  height: "25px !important",
                  borderRadius: "4px !important",
                  width: "auto !important",
                  backgroundColor: "transparent",
                  "& .MuiSelect-select": {
                    color: "#000",
                    padding: "0px 0px 0px 0px !important",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    minWidth: "0px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em style={{ color: "#000" }}>Select</em>
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="experienced">
                  experienced
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="seasoned">
                  seasoned
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="qualified">
                  qualified
                </MenuItem>
              </Select>
            </FormControl>
            individuals. Encouraging business schools like Harvard, Stanford,
            the University of Chicago, and Wharton have
            <FormControl
              size="small"
              sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
            >
              <Select
                value={answers.blank3}
                onChange={handleAnswerChange("blank3")}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  height: "25px !important",
                  borderRadius: "4px !important",
                  width: "auto !important",
                  backgroundColor: "transparent",
                  "& .MuiSelect-select": {
                    color: "#000",
                    padding: "0px 0px 0px 0px !important",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    minWidth: "0px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em style={{ color: "#000" }}>Select</em>
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="shifted">
                  shifted
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="moved">
                  moved
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="changed">
                  changed
                </MenuItem>
              </Select>
            </FormControl>
            their focus from the unofficial requirement of four years of work
            experience for admission to recent college graduates and so-called
            "early career professionals" with only a few years of
            <FormControl
              size="small"
              sx={{ mx: 1, display: "inline-block", verticalAlign: "middle" }}
            >
              <Select
                value={answers.blank4}
                onChange={handleAnswerChange("blank4")}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                  height: "25px !important",
                  borderRadius: "4px !important",
                  width: "auto !important",
                  backgroundColor: "transparent",
                  "& .MuiSelect-select": {
                    color: "#000",
                    padding: "0px 0px 0px 0px !important",
                    border: "1px solid rgba(255, 255, 255, 0.3)",
                    minWidth: "0px !important",
                  },
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none !important",
                  },
                }}
              >
                <MenuItem value="">
                  <em style={{ color: "#000" }}>Select</em>
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="experience">
                  experience
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="expertise">
                  expertise
                </MenuItem>
                <MenuItem style={{ color: "#000" }} value="time">
                  time
                </MenuItem>
              </Select>
            </FormControl>
            in the workforce in an effort to attract new students.
          </Typography>
        </Box>
      </Box>

      {/* Fixed Footer */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {79} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{ color: "#fff" }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FillInBlanksSelect;
