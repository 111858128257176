// FillInBlanks.jsx
import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AudioPlayer from "components/AudioPlayer";
import { useNavigate } from "react-router-dom";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function FillInBlanks() {
  const navigate = useNavigate();
  const [openSearchModal, setOpenSearchModal] = useState(false);

  // Available words for dragging
  const [availableWords, setAvailableWords] = useState([
    { id: "analysis", content: "analysis" },
    { id: "objectives", content: "objectives" },
    { id: "concepts", content: "concepts" },
    { id: "comparison", content: "comparison" },
  ]);

  // State for blanks
  const [blanks, setBlanks] = useState({
    blank1: null,
    blank2: null,
    blank3: null,
    blank4: null,
  });

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // If dragging from available words to a blank
    if (
      source.droppableId === "availableWords" &&
      destination.droppableId.startsWith("blank")
    ) {
      const word = availableWords[source.index];
      const newAvailableWords = [...availableWords];
      newAvailableWords.splice(source.index, 1);

      // If there was a word in the destination blank, put it back in available words
      if (blanks[destination.droppableId]) {
        newAvailableWords.push(blanks[destination.droppableId]);
      }

      setAvailableWords(newAvailableWords);
      setBlanks({
        ...blanks,
        [destination.droppableId]: word,
      });
    }

    // If dragging from a blank back to available words
    if (
      source.droppableId.startsWith("blank") &&
      destination.droppableId === "availableWords"
    ) {
      const word = blanks[source.droppableId];
      setAvailableWords([...availableWords, word]);
      setBlanks({
        ...blanks,
        [source.droppableId]: null,
      });
    }
  };

  const renderBlank = (blankId) => (
    <Droppable droppableId={blankId}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.droppableProps}
          sx={{
            display: "inline-block",
            mx: 1,
            minWidth: 120,
            minHeight: 32,
            border: "1px solid rgba(255,255,255,0.3)",
            borderRadius: 1,
            bgcolor: snapshot.isDraggingOver
              ? "rgba(255,255,255,0.2)"
              : "rgba(255,255,255,0.1)",
            p: 0.5,
            transition: "background-color 0.2s ease",
          }}
        >
          {blanks[blankId] && (
            <Draggable draggableId={blanks[blankId].id} index={0}>
              {(provided, snapshot) => (
                <Box
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  sx={{
                    p: 1,
                    bgcolor: "#00A693",
                    borderRadius: 1,
                    boxShadow: snapshot.isDragging ? 3 : 0,
                  }}
                >
                  {blanks[blankId].content}
                </Box>
              )}
            </Draggable>
          )}
          {provided.placeholder}
        </Box>
      )}
    </Droppable>
  );

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            Fill in Blanks
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>1/296</Typography>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => setOpenSearchModal(true)}
          >
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ px: 2, pt: 3, pb: "80px" }}>
        <Typography
          sx={{ color: "rgba(255,255,255,0.8)", mb: 2, fontStyle: "italic" }}
        >
          In the text below some words are missing. Drag words from the box
          below to the appropriate place in the text.
        </Typography>

        <DragDropContext onDragEnd={handleDragEnd}>
          {/* Text with blanks */}
          <Box sx={{ mt: 4, lineHeight: 1.8, fontSize: "16px" }}>
            <Typography component="div" sx={{ color: "rgba(255,255,255,0.9)" }}>
              Management accounting is concerned with providing information and{" "}
              {renderBlank("blank1")} to managers to help them plan, evaluate
              and control activities, in order to achieve an organization's{" "}
              {renderBlank("blank2")}. Whereas financial accounting is concerned
              with reporting on the past financial performance of an
              organization, management accounting is essentially concerned with
              improving its future performance. In order to understand the{" "}
              {renderBlank("blank3")} and principles of management accounting it
              is necessary first to have some {renderBlank("blank4")} of what
              managers do!
            </Typography>
          </Box>

          {/* Draggable words section */}
          <Droppable droppableId="availableWords" direction="horizontal">
            {(provided) => (
              <Box
                ref={provided.innerRef}
                {...provided.droppableProps}
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  mt: 4,
                  p: 2,
                  bgcolor: "rgba(255,255,255,0.05)",
                  borderRadius: 1,
                }}
              >
                {availableWords.map((word, index) => (
                  <Draggable key={word.id} draggableId={word.id} index={index}>
                    {(provided, snapshot) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        sx={{
                          p: 1,
                          bgcolor: "#262842",
                          borderRadius: 1,
                          cursor: "grab",
                          boxShadow: snapshot.isDragging ? 3 : 0,
                          "&:hover": {
                            bgcolor: "#2F315A",
                          },
                        }}
                      >
                        {word.content}
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        {/* Fixed Footer with Background */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "#1A1B2E",
            borderTop: "1px solid rgba(255,255,255,0.1)",
            display: "flex",
            justifyContent: "center",
            p: 2,
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#00A693",
              "&:hover": {
                bgcolor: "#009688",
              },
              textTransform: "none",
              px: 4,
              py: 1,
              fontSize: "16px",
              borderRadius: "4px",
              minWidth: "120px",
              color: "#fff",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {79} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FillInBlanks;
