// TextAreaCard.jsx
import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

function Essay(props) {
  const {
    questionTitle = "Essay",
    questionInstructions = "You will have 20 minutes to plan, write and revise an essay about the topic below. Your response will be judged on how well you develop a position, organize your ideas, present supporting details, and control the elements of standard written English. You should write 200-300 words.",
    questionText = "Some people say the elderly are unsuitable to take part in some activities. Please give an example of the activities, and give the maximum age for it.",
    timeLimit = 20,
    wordLimit = { min: 200, max: 300 },
    searchItems = [],
    path = "/",
    onSubmit = () => {},
  } = props;

  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [timeRemaining, setTimeRemaining] = useState(timeLimit * 60);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds.toString().padStart(2, "0")}`;
  };

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const handleSubmit = async () => {
    if (wordCount < wordLimit.min) {
      alert(`Please write at least ${wordLimit.min} words.`);
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmit(text);
      navigate(path);
    } catch (error) {
      console.error("Submit error:", error);
      alert("Failed to submit. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box sx={{ bgcolor: "#1A1F2E", minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => navigate(`${path}`)}
          >
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography variant="h5" sx={{ color: "#fff" }}>
            {questionTitle}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-chevron-left" />
            </IconButton>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
            >
              <i className="fas fa-search" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-rotate" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-chevron-right" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Container maxWidth="lg" sx={{ mt: 4 , pb: "80px" }}>
        {/* Instructions */}
        <Typography
          sx={{
            color: "#fff",
            fontStyle: "italic",
            mb: 3,
          }}
        >
          {questionInstructions}
        </Typography>

        {/* Question */}
        <Box
          sx={{
            bgcolor: "rgba(255,255,255,0.1)",
            p: 3,
            borderRadius: 1,
            mb: 3,
            width: "100%",
          }}
        >
          <Typography
            sx={{
              color: "#fff",
              wordWrap: "break-word",
              overflowWrap: "break-word",
            }}
          >
            {questionText}
          </Typography>
        </Box>

        {/* Answer Box */}
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 1,
            p: 3,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
            </Box>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={10}
            value={text}
            placeholder="Type the answer here"
            onChange={handleTextChange}
            disabled={timeRemaining === 0}
            sx={{
              "& .MuiOutlinedInput-root": {
                backgroundColor: "#E8E9F3 !important",
                width: "100% !important",
                "&:hover": {
                  backgroundColor: "#E8E9F3 !important",
                },
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
              },
              "& .MuiOutlinedInput-input": {
                width: "100% !important",
                backgroundColor: "#E8E9F3 !important",
              },
            }}
          />
        </Box>

        {/* Timer and Submit Button */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: 2,
            px: 2,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography sx={{ color: "#fff" }}>Time remaining</Typography>
            <Typography sx={{ color: "#fff", fontWeight: "bold" }}>
              {formatTime(timeRemaining)}
            </Typography>
          </Box>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={
              isSubmitting || timeRemaining === 0 || wordCount < wordLimit.min
            }
            sx={{
              bgcolor: "#4CAF50",
              color: "#fff",
              textTransform: "none",
              "&:hover": {
                bgcolor: "#45a049",
              },
              "&:disabled": {
                bgcolor: "#cccccc",
              },
            }}
          >
            {isSubmitting ? "Submitting..." : "Submit"}
          </Button>
        </Box>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {searchItems.length} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={item.id}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    {item.isNew && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        New
                      </Box>
                    )}
                    {item.isVIP && (
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        VIP
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default Essay;
