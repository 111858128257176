import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
  LinearProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";

import AudionPlayer from "../../../../components/AudioPlayer";
import ReadAloudMicrophoneRecorder from "../../../../components/Recorder/ReadAloudMicrophone";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "../../../../components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const dummyScores = {
  pronunciation: 85,
  fluency: 78,
  rhythm: 90,
  overall: 84,
};

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

const ReadAloud = ({ questionData }) => {
  console.log(questionData);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
      console.log("Question not found.");
    } else {
      // If the question is found, show the index in the length of the data
      console.log("Index in the array:", questionIndex);
      console.log("Length of the data:", questionData.questions?.length);
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = async (recording) => {
    console.log("Recording completed:", recording);
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
            3
        ),
      });

      const formData = new FormData();
      formData.append("questionId", id);

      const scroe = {
        category: Category,
        overall: `${scores.overall}/90`,
        content: scores.content,
        pronunciation: scores.pronunciation,
        fluency: scores.fluency,
        text: recordedAudio.transcription,
      };

      formData.append("score", JSON.stringify(scroe));

      formData.append("ansfile", recording.blob);
      const ans_sub = await postApi(
        `${API_PATH.STUDENTS.SUBMIT_ANS}`,
        formData
      );
      console.log(ans_sub, "  snshbuvsuvduv");
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
    }
  };

  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      {/* Fixed Header */}
      {/* <p>Current ID: {questionData.questions[currentIndex].id}</p> */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Read Aloud
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
        }}
      >
        {/* Instructions Text */}
        <Typography
          sx={{
            color: "#fff",
            mt: 8,
            mb: 2,
            fontSize: "17px",
            fontStyle: "italic",
          }}
        >
          Look at the text below. In 40 seconds, you must read this text aloud
          as naturally and clearly as possible. You have 40 seconds to Read
          Aloud.
        </Typography>

        {/* <RepeatParagraphAloud /> */}
        <RepeatParagraphAloud
          audioText={JSON.stringify(questionDataApi.audio_text)}
        />

        {/* Instructions */}
        <Paper
          sx={{
            p: 2,
            my: 3,
            color: "#fff",
            background: "#1A1A2E",
            border: "0",
            boxShadow: "0",
            fontSize: "17px",
          }}
        >
          <Typography>{questionDataApi.question_text}</Typography>
        </Paper>

        {/* Recording Section */}
        <Paper sx={{ p: 4 }}>
          <ReadAloudMicrophoneRecorder
            onRecordingComplete={handleRecordingComplete}
            expectedText={questionDataApi.question_text}
            // maxRecordingTime={45}
          />
          {recordedAudio && (
            <Box sx={{ mt: 3 }}>
              <AudioComponent
                src={recordedAudio.url}
                type={recordedAudio.mimeType}
              />
              <Button
                variant="contained"
                sx={{
                  mt: 2,
                  bgcolor: "#4caf50",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#357a38",
                  },
                  "&.MuiButton-contained": {
                    backgroundColor: "#4caf50",
                    "&:hover": {
                      backgroundColor: "#357a38",
                    },
                  },
                }}
                onClick={() => setOpenAnalysisModal(true)}
              >
                Analyze Recording
              </Button>
            </Box>
          )}
        </Paper>

        {/* Analysis Modal */}
        <Modal
          open={openAnalysisModal}
          onClose={() => setOpenAnalysisModal(false)}
          aria-labelledby="analysis-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "auto",
              bgcolor: "#FFFFFF",
              borderRadius: "12px",
              p: 3,
              outline: "none",
              textAlign: "center",
            }}
          >
            {/* Green Checkmark Circle */}

            <Box
              sx={{
                width: "64px",
                height: "64px",
                bgcolor: "#00E676",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 20px",
              }}
            >
              <i
                className="fas fa-check"
                style={{ color: "#FFFFFF", fontSize: "32px" }}
              />
            </Box>

            <Typography
              variant="h6"
              sx={{
                color: "#000000",
                fontSize: "24px",
                fontWeight: 500,
                mb: 3,
              }}
            >
              Score:{scores.overall}/90.0
            </Typography>
            {/* Score Categories */}

            <Box sx={{ mb: 3 }}>
              {[
                { label: "Content", score: scores.content, total: 90 },
                { label: "Fluency", score: scores.fluency, total: 90 },
                {
                  label: "Pronunciation",
                  score: scores.pronunciation,
                  total: 90,
                },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    mb: 2,
                    width: "450px",
                  }}
                >
                  <Typography
                    sx={{
                      flex: 1,
                      textAlign: "left",
                      color: "#666666",
                      width: "20%",
                      fontSize: "18px",
                      fontWeight: "bold",
                    }}
                  >
                    {item.label}
                  </Typography>

                  <Box
                    sx={{
                      flex: 2,
                      height: "6px",
                      bgcolor: "#F0F0F0",
                      borderRadius: "3px",
                      position: "relative",
                      mx: 2,
                      width: "60%",
                    }}
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        left: 0,
                        top: 0,
                        height: "100%",
                        paddingRight: `${(item.score / item.total) * 100}%`,
                        bgcolor: "#00E676",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>

                  <Typography
                    sx={{ color: "#666666", minWidth: "70px", width: "20%" }}
                  >
                    {item.score}/{item.total}
                  </Typography>
                </Box>
              ))}
            </Box>
            {/* Transcription Display (Optional) */}
            {recordedAudio?.transcription && (
              <Box sx={{ mb: 3, textAlign: "left" }}>
                <Typography sx={{ color: "#666666", fontWeight: 500, mb: 1 }}>
                  Your Speech:
                </Typography>

                <Typography sx={{ color: "#666666", fontSize: "14px" }}>
                  {recordedAudio.transcription}
                </Typography>
              </Box>
            )}
            {/* OK Button */}

            <Button
              fullWidth
              sx={{
                bgcolor: "#00E676",
                color: "#FFFFFF",
                borderRadius: "25px",
                py: 1.5,
                textTransform: "none",
                fontSize: "16px",
                "&:hover": {
                  bgcolor: "#00C853",
                },
              }}
              onClick={() => setOpenAnalysisModal(false)}
            >
              Ok
            </Button>
          </Box>
        </Modal>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {100} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default ReadAloud;
