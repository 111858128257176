import TextAreaCard from "components/TextAreaCard/TextAreaCard";
import React from "react";


const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function SummarizeTextAcademic() {
  return (
    <TextAreaCard 
      questionTitle="Summarize Text Academic"
      searchItems={searchItems}
      path={"/listening/STA"}
    />
  );
}

export default SummarizeTextAcademic;
