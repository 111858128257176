import { Box, Button, Paper, Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export const QuestionCard = ({
  number,
  id,
  title,
  attempts,
  completed,
  onNewClick,
}) => (
  console.log(id,),
  <Paper
    onClick={() => onNewClick(id)}
    elevation={0}
    sx={{
      p: 2,
      mb: 1.5,
      borderRadius: 2,
      border: "1px solid #e0e0e0",
      ":hover": {
        boxShadow: "0 0 10px #00BFA6",
        cursor: "pointer",
      },
    }}
  >
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "16px", color: "#2c2c2c" }}>
          {number}. {title ?title :id}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", mt: 0.5 }}>
          <Typography sx={{ color: "#666", fontSize: "14px" }}>
            Attempted: {attempts}
          </Typography>
          {completed && (
            <CheckCircleIcon sx={{ ml: 1, color: "#4CAF50", fontSize: 18 }} />
          )}
        </Box>
      </Box>
      <Box>
        <Button
          variant="contained"
          size="small"
          sx={{
            bgcolor: "#ffd700",
            color: "#000",
            mr: 1,
            textTransform: "none",
            minWidth: "60px",
            "&:hover": { bgcolor: "#ffed4a" },
            fontSize: "13px",
          }}
        >
          New
        </Button>
        <Button
          variant="contained"
          size="small"
          sx={{
            bgcolor: "#ffd700",
            color: "#000",
            textTransform: "none",
            minWidth: "60px",
            "&:hover": { bgcolor: "#ffed4a" },
            fontSize: "13px",
          }}
        >
          VIP
        </Button>
      </Box>
    </Box>
  </Paper>
);
