import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
  LinearProgress,
  Grid,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";

import AudionPlayer from "../../../../components/AudioPlayer";
import ReadAloudMicrophoneRecorder from "../../../../components/Recorder/ReadAloudMicrophone";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "../../../../components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import styled from "@emotion/styled";
import { postApi } from "services/axiosInstance";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const SingleAnswerReading = ({ questionData }) => {
  console.log(questionData);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
      console.log("Question not found.");
    } else {
      // If the question is found, show the index in the length of the data
      console.log("Index in the array:", questionIndex);
      console.log("Length of the data:", questionData.questions?.length);
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [submitedAns, setSubmitedAns] = useState(null);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      console.log(res.data.data, "Question");
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
        console.log(res.data.data, "Question");
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
    console.log(`Selected answer: ${index}`, questionDataApi.choices[index]);
  };

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    console.log("Recording completed:", recording);
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
          3
        ),
      });
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer(null);
    }
  };

  const handleSubmit = async () => {
    const correctChoice = questionDataApi.choices.findIndex(
      (choice) => choice.is_correct
    );
    const choiceLabels = ["A", "B", "C", "D"];
    // Find the correct answer and its corresponding label
    const correctChoice1 = questionDataApi.choices.find(
      (choice) => choice.is_correct
    );
    const correctAnswerLabel =
      choiceLabels[questionDataApi.choices.indexOf(correctChoice1)];
    const selectedAnswerLabel = choiceLabels[selectedAnswer];

    if (selectedAnswer === correctChoice) {
      console.log("Correct Answer Selected!", selectedAnswerLabel);
      console.log(selectedAnswer)
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
      console.log("Incorrect Answer Selected!");
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabel,
      correctAnswers: correctAnswerLabel,
    };
    console.log(payload, "answer");
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
        console.log(answer.data.data);
      }
    } catch (error) {}
  };

  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      {/* Fixed Header */}
      {/* <p>Current ID: {questionData.questions[currentIndex].id}</p> */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Single Answer
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>

              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ padding: 2 }}>
        <Grid container spacing={2}>
          {/* First Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                padding: 2,
                textAlign: "center",
              }}
            >
              <Paper
                sx={{ marginTop: "40px", boxShadow: "0", marginX: "20px" }}
              >
                <Typography
                  sx={{
                    color: "#fff",
                    background: "#1A1A2E",
                    fontSize: "17px",
                  }}
                >
                  {questionDataApi.audio_text}1
                </Typography>
              </Paper>
            </Box>
          </Grid>
          {/* Second Column */}
          <Grid item xs={12} md={6}>
            <Box
              sx={{
                // padding: 2,
                textAlign: "center",
                // borderRadius: 1,
              }}
            >
              <Container
                maxWidth="lg"
                sx={{
                  pt: "80px",
                  p: 2,
                }}
              >
                {/* Added Instructions Text */}
                <Typography
                  sx={{
                    color: "#fff",
                    fontSize: "14px",
                    mt: "30px",
                    fontStyle: "italic",
                  }}
                >
                  Look at the text below. In 40 seconds, you must read this text
                  aloud as naturally and clearly as possible. You have 40
                  seconds to read aloud.
                </Typography>

                {/* <RepeatParagraphAloud /> */}
                {/* <RepeatParagraphAloud audioText={JSON.stringify(questionDataApi.audio_text)} /> */}

                {/* Instructions */}
                <Paper sx={{ marginY: "20px" }}>
                  <Typography
                    sx={{
                      color: "#fff",
                      background: "#1A1A2E",
                      fontSize: "17px",
                    }}
                  >
                    {questionDataApi.question_text}
                  </Typography>
                </Paper>
                {/* Recording Section */}

                {/* Answer Options */}
                <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
                  {questionDataApi.choices?.map((choice, index) => (
                    <Box
                      key={index}
                      onClick={() => { submitedAns === null && handleAnswerSelect(index) }}
                      sx={{
                        bgcolor:
                          submitedAns === null // Before submission (no color change yet)
                            ? selectedAnswer === index // If this option is selected
                              ? "#00BFA6" // Keep selected option highlighted (light blue)
                              : "rgba(52, 58, 64, 0.8)" // Default neutral color for non-selected answers
                            : // After submission, update colors based on correctness
                            selectedAnswer === index // If this option is selected by the user
                              ? submitedAns && choice.is_correct
                                ? "green" // Correct answer: green
                                : "red" // Wrong answer: red
                              : choice.is_correct // Highlight correct answer
                                ? "green" // Correct answer highlighted in green
                                : "rgba(52, 58, 64, 0.8)", // Neutral color for other unselected answers
                        p: 2.5,
                        borderRadius: "8px",
                        cursor: "pointer",
                        display: "flex",
                        alignItems: "center",
                        transition: "all 0.2s ease",
                        "&:hover": {
                          bgcolor:
                            submitedAns === null
                              ? selectedAnswer === index // If option is selected, it will remain highlighted on hover
                                ? "#00BFA6" // Keep selected option hover color same
                                : "rgba(52, 58, 64, 0.9)" // Darken other options on hover
                              : selectedAnswer === index // If this option is selected by the user
                                ? submitedAns && choice.is_correct
                                  ? "green" // Correct answer: green
                                  : "red" // Wrong answer: red
                                : choice.is_correct // Highlight correct answer
                                  ? "green" // Correct answer highlighted in green
                                  : "rgba(52, 58, 64, 0.8)", // Default hover color after submission (for non-selected)
                        },
                      }}
                    >
                      <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                        {choice.choice_text}
                      </Typography>
                    </Box>
                  ))}
                </Box>

              </Container>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Fixed Footer with Background */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Button
          onClick={handleSubmit}
          sx={{
            mt: 3,
            bgcolor: "#00BFA6",
            color: "#fff",
            borderRadius: "8px",
            p: 2,
            "&:hover": { bgcolor: "#009e8b" },
          }}
        >
          Submit Answer
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {100} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SingleAnswerReading;
