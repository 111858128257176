import React from 'react'
import Questions from './questions'

const EssayQs = () => {
  return (
    <div>
        <Questions />
    </div>
  )
}

export default EssayQs