import React, { useState, useEffect } from 'react';
import { IconButton, Typography, Box } from '@mui/material';
import { Mic, Stop } from '@mui/icons-material';

const ReadAloudMicrophoneRecorder = ({ onRecordingComplete }) => {
const [isRecording, setIsRecording] = useState(false);
const [audioStream, setAudioStream] = useState(null);
const [timer, setTimer] = useState(40);
const [mediaRecorder, setMediaRecorder] = useState(null);
const [chunks, setChunks] = useState([]);

useEffect(() => {
  return () => {
    if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
    }
  };
}, [audioStream]);

const startRecording = async () => {
  try {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    setAudioStream(stream);
    const recorder = new MediaRecorder(stream);
    setMediaRecorder(recorder);
    setIsRecording(true);
    setChunks([]);

    recorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        setChunks(prevChunks => [...prevChunks, event.data]);
      }
    };

    recorder.onstop = () => {
      const blob = new Blob(chunks, { type: 'audio/webm' });
      if (onRecordingComplete) {
        onRecordingComplete(blob);
      }
    };

    recorder.start();

    const interval = setInterval(() => {
      setTimer((prev) => {
        if (prev <= 1) {
          clearInterval(interval);
          stopRecording();
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  } catch (err) {
    console.error("Error accessing microphone:", err);
  }
};

const stopRecording = () => {
  if (mediaRecorder && mediaRecorder.state !== 'inactive') {
    mediaRecorder.stop();
    if (audioStream) {
      audioStream.getTracks().forEach(track => track.stop());
    }
    setIsRecording(false);
    setTimer(40);
  }
};

return (
  <Box sx={{ textAlign: 'center' }}>
    <IconButton
      onClick={isRecording ? stopRecording : startRecording}
      sx={{
        bgcolor: isRecording ? '#f44336' : '#4caf50',
        p: 4,
        mb: 2,
        '&:hover': {
          bgcolor: isRecording ? '#d32f2f' : '#45a049',
        },
      }}
    >
      {isRecording ? (
        <Stop sx={{ color: 'white', fontSize: 40 }} />
      ) : (
        <Mic sx={{ color: 'white', fontSize: 40 }} />
      )}
    </IconButton>
    <Typography color="text.secondary">
      {isRecording 
        ? `Recording... ${timer}s remaining` 
        : timer === 40 
          ? 'Click to start recording' 
          : `Recording completed`}
    </Typography>
  </Box>
);
};

export default ReadAloudMicrophoneRecorder;