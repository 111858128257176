import AnswersCard from "components/AnswerCard/AnswerCard";

const Answers = () => {
  const statements = [
    "Studying history helps us to live in today's world",
    "Studying history is not just memorization",
    "The role of education is to help students deal with real life",
    "Students should study both national and international history",
  ];

  const searchItems = [
    { id: "SA #82", attempted: 0 },
    { id: "SA #81", attempted: 0 },
    { id: "SA #80", attempted: 0 },
    { id: "SA #78", attempted: 0 },
    { id: "SA #77", attempted: 0 },
  ];

  return (
    <AnswersCard
      statements={statements}
      searchItems={searchItems}
      questionNumber={10}
      totalQuestions={79}
      path="/listening/MCQ"
      name="Multiple Answers"
      questionText="What is the passage's major point?"
      instructions="Listen to the recording and answer the multiple-choice question by selecting the correct response. Only one response is correct."
    />
  );
};

export default Answers