import Essay from "components/EssayCard/Essay";
import React from "react";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function SummarizeTextCore() {
  return (
    <Essay
      questionTitle="Essay"
      questionInstructions="Read the text below & summarize it using between 25 and 50 words. Type your response in the box at the bottom of the screen. You have 10 minutes to finish this task. Your response will be judged on the quality of your writing and on how well your response presents the key points."
      questionText="Some people say the elderly..."
      timeLimit={20}
      wordLimit={{ min: 200, max: 300 }}
      searchItems={searchItems}
      // path="/writing/ESSAY"
      onSubmit={"console.log"}
    />
  );
}

export default SummarizeTextCore;
