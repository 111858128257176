import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Modal,
  Button,
} from "@mui/material";
import AudioPlayer from "components/AudioPlayer";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const AnswersCard = ({
  statements,
  searchItems,
  questionNumber,
  totalQuestions,
  questionText,
  instructions,
  path,
  name,
}) => {
  const navigate = useNavigate();
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleAnswerSelect = (index) => {
    setSelectedAnswer(index);
  };

  return (
    <Box sx={{ bgcolor: "#1A1B2E", height: "100vh", display: "flex", flexDirection: "column" }}>
      {/* Fixed Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <IconButton sx={{ color: "#fff" }} onClick={() => navigate(`${path}`)}>
          <i className="fas fa-arrow-left" />
        </IconButton>

        <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
          {name}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}>
          <Typography sx={{ color: "#fff", fontSize: "14px" }}>
            {questionNumber}/{totalQuestions}
          </Typography>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => setOpenSearchModal(true)}
          >
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Scrollable Content */}
      <Box 
        sx={{ 
          flex: 1,
          overflowY: "auto",
          p: 3,
          pb: "80px", // Add padding to prevent content from being hidden behind the footer
        }}
      >
        <Typography
          sx={{
            fontStyle: "italic",
            mb: 2,
            fontSize: "14px",
            color: "#B7B7B7",
            border: "1px solid #B7B7B7",
            borderRadius: "8px",
            py: "4px",
            px: "8px",
          }}
        >
          {instructions}
        </Typography>

        <Typography sx={{ mb: 3, color: "#fff", fontSize: "16px" }}>
          {questionText}
        </Typography>

        {/* Audio Player */}
        <AudioPlayer />

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {statements.map((statement, index) => (
            <Box
              key={index}
              onClick={() => handleAnswerSelect(index)}
              sx={{
                bgcolor:
                  selectedAnswer === index
                    ? "#00BFA6"
                    : "rgba(52, 58, 64, 0.8)",
                p: 2.5,
                borderRadius: "8px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                transition: "all 0.2s ease",
                "&:hover": {
                  bgcolor:
                    selectedAnswer === index
                      ? "#00BFA6"
                      : "rgba(52, 58, 64, 0.9)",
                },
              }}
            >
              <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                {statement}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {/* Fixed Footer with Background */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {totalQuestions} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default AnswersCard;