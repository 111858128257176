import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";
import AudionPlayer from "components/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import RepeatParagraphAloud from "components/AudioPlayer/AudioPlayer";

const searchItems = [
  { id: "RS #82", attempted: 0 },
  { id: "RS #81", attempted: 0 },
  { id: "RS #80", attempted: 0 },
  { id: "RS #78", attempted: 0 },
  { id: "RS #77", attempted: 0 },
];

const RepeatSentenceAnswers = ({ questionData }) => {
  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);


  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
      console.log("Question not found.");
    } else {
      // If the question is found, show the index in the length of the data
      console.log("Index in the array:", questionIndex);
      console.log("Length of the data:", questionData.questions?.length);
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex()
    }

  }, [questionData]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }

  }, []);


  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
    }
  };


  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleRecordingComplete = (recordedData) => {
    console.log('Recording completed:', recordedData);
    // Handle the recorded audio data here
  };

  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      <Container maxWidth="lg">
        {/* Header */}
        <Box
          sx={{
            bgcolor: "#1A1B2E",
            p: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderBottom: "1px solid rgba(255,255,255,0.1)",
          }}
        >
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => navigate(-1)}
          >
            <i className="fas fa-arrow-left" />
          </IconButton>

          <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
            Repeat Sentence
          </Typography>

          <Box
            sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
          >
            <Typography sx={{ color: "#fff", fontSize: "14px" }}>
              {currentQuestion}/{questionData.totalQuestions}
            </Typography>
            <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
              <i className="fas fa-chevron-left" />
            </IconButton>

            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
            >
              <i className="fas fa-search" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-rotate" />
            </IconButton>

            <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
              <i className="fas fa-chevron-right" />
            </IconButton>
          </Box>
        </Box>

        {/* Instructions */}
        <Paper sx={{ p: 2, mb: 2, mt: 2, color: "#fff", background: "#1A1A2E", border: "0", boxShadow: "0", fontSize: "17px" }}>
          <Typography sx={{ fontStyle: "italic" }}>
            You will hear a sentence. Please repeat the sentence exactly as you hear it. You will hear the sentence only once.
          </Typography>
        </Paper>

        <RepeatParagraphAloud audioText={JSON.stringify(questionDataApi.audio_text)} />

        {/* Recording Section */}
        <Paper sx={{ p: 4 }}>
          <MicrophoneRecorder onRecordingComplete={handleRecordingComplete} />
        </Paper>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {100} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default RepeatSentenceAnswers;