import React from 'react'
import Questions from './questions'

const RepeatSentence = () => {
  return (
    <div>
        <Questions />
    </div>
  )
}

export default RepeatSentence