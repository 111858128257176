import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, Paper, Select, Modal, MenuItem } from '@mui/material';
import AudioPlayer from 'components/AudioPlayer';
import { useNavigate } from 'react-router-dom';

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function IncorrectWords() {
  const navigate = useNavigate()
  const [selectedWords, setSelectedWords] = useState([]);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const text = "We're going to have a short written assessment which will happen every fortnight. You will all be taken up into small groups, so feel free to ask any questions as I go along. And we'll also ask you to participate . So if you'd all like to open your books to page one.";

  // Remove highlightableWords array since all words will be selectable
  const words = text.split(' ').map((word, index) => ({
    word,
    id: `${word}-${index}`,
  }));

  const handleWordClick = (wordId) => {
    if (selectedWords.includes(wordId)) {
      setSelectedWords(selectedWords.filter(id => id !== wordId));
    } else {
      setSelectedWords([...selectedWords, wordId]);
    }
  };

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box sx={{
           bgcolor: "#1A1B2E",
           p: 2,
           display: "flex",
           alignItems: "center",
           justifyContent: "space-between",
           borderBottom: "1px solid rgba(255,255,255,0.1)",
           position: "sticky",
           top: 0,
           zIndex: 1000,
      }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate('/listening/IWQ')}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            Incorrect words
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}>
          <IconButton sx={{ color: "#fff" }}    onClick={() => setOpenSearchModal(true)}>
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Instructions */}
      <Box sx={{   flex: 1,
          overflowY: "auto",
          p: 3,
          pb: "80px",  }}>
        <Paper sx={{
          bgcolor: "#282C37",
          p: 2,
          mb: 3,
          borderRadius: 2,
          color: "#fff"
        }}>
          <Typography sx={{ fontSize: "14px" }}>
            You will hear a recording. Below is a transcription of the recording. Some words in the transcription differ from what the speaker(s) said. Please click on the words that are different.
          </Typography>
        </Paper>

        {/* Audio Player */}
        <AudioPlayer />

        {/* Text Content */}
        <Box sx={{ mb: 4, lineHeight: 2 }}>
          {words.map((item) => (
            <span
              key={item.id}
              onClick={() => handleWordClick(item.id)}
              style={{
                cursor: 'pointer',
                backgroundColor: selectedWords.includes(item.id) ? '#FFB800' : 'transparent',
                color: selectedWords.includes(item.id) ? '#000' : '#fff',
                borderRadius: '4px',
                margin: '0 2px',
                transition: 'all 0.2s ease'
              }}
            >
              {item.word}{' '}
            </span>
          ))}
        </Box>

        {/* Fixed Footer with Background */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Box>
      </Box>
       {/* Search Modal */}
       <Modal
          open={openSearchModal}
          onClose={() => setOpenSearchModal(false)}
          aria-labelledby="search-modal"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "#1A1B2E",
              borderRadius: "8px",
              p: 3,
              outline: "none",
              maxHeight: "80vh",
              overflow: "auto",
            }}
          >
            {/* Modal Header */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 3,
                borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
                pb: 2,
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Select
                  value="All"
                  size="small"
                  sx={{
                    color: "#fff",
                    height: "32px",
                    minWidth: "100px",
                    ".MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255,255,255,0.3)",
                      borderRadius: "4px",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(255,255,255,0.5)",
                    },
                  }}
                >
                  <MenuItem value="All">All</MenuItem>
                </Select>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    gap: 1,
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {79} Qs
                  </Typography>
                </Box>
              </Box>
              <IconButton
                onClick={() => setOpenSearchModal(false)}
                sx={{
                  color: "#fff",
                  p: 1,
                  "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
                }}
              >
                <i className="fas fa-times" />
              </IconButton>
            </Box>
  
            {/* Search Items */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {searchItems.map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    bgcolor: "#262842",
                    borderRadius: "8px",
                    p: 2,
                    cursor: "pointer",
                    "&:hover": {
                      bgcolor: "#2F315A",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                      {index + 1}. {item.id}
                    </Typography>
                    <Box sx={{ display: "flex", gap: 1 }}>
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        New
                      </Box>
                      <Box
                        sx={{
                          bgcolor: "#FFC107",
                          px: 1,
                          borderRadius: "4px",
                          fontSize: "12px",
                          fontWeight: 500,
                          color: "#000",
                        }}
                      >
                        VIP
                      </Box>
                    </Box>
                  </Box>
                  <Box sx={{ mt: 1 }}>
                    <Typography
                      sx={{
                        color: "rgba(255,255,255,0.6)",
                        fontSize: "12px",
                        display: "flex",
                        alignItems: "center",
                        gap: 0.5,
                      }}
                    >
                      Attempted: {item.attempted}
                    </Typography>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
        </Modal>
    </Box>
  );
}

export default IncorrectWords;