import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Container,
  Paper,
  MenuItem,
  Select,
  Modal,
  Button,
  LinearProgress,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import MicrophoneRecorder from "components/Recorder/MicrophoneRecording";

import AudionPlayer from "../../../../components/AudioPlayer";
import ReadAloudMicrophoneRecorder from "../../../../components/Recorder/ReadAloudMicrophone";
import AudioComponent from "components/Recorder/AudioComponent";
import RepeatParagraphAloud from "../../../../components/AudioPlayer/AudioPlayer";
import { getApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import { postApi } from "services/axiosInstance";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const MultipleAnswerListening = ({ questionData }) => {
  console.log(questionData);
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  let id = location.pathname.split("/")[2];

  const navigate = useNavigate();

  const findIndex = () => {
    const questionIndex = questionData.questions?.findIndex(
      (question) => question.id === id
    );

    setCurrentIndex(questionIndex);
    setCurrentQuestion(questionIndex + 1);
    if (questionIndex === -1) {
      console.log("Question not found.");
    } else {
      // If the question is found, show the index in the length of the data
      console.log("Index in the array:", questionIndex);
      console.log("Length of the data:", questionData.questions?.length);
    }
  };

  useEffect(() => {
    if (questionData) {
      findIndex();
    }
  }, [questionData]);

  const [currentQuestion, setCurrentQuestion] = useState(1);
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [choices, setChoices] = useState([]);
  const [openAnalysisModal, setOpenAnalysisModal] = useState(false);
  const [scores, setScores] = useState({
    content: 0,
    pronunciation: 0,
    fluency: 0,
    overall: 0,
  });

  const [questionid, setQuestionId] = useState();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [questionDataApi, setQuestionDataApi] = useState([]);

  const getQuestionBtID = async (id) => {
    try {
      const res = await getApi(`${API_PATH.STUDENTS.GET_QUESTION_BY_ID}/${id}`);
      if (res.status === 200) {
        setQuestionDataApi(res.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (id) {
      getQuestionBtID(id);
    }
  }, []);

  const [selectedAnswer, setSelectedAnswer] = useState([]);
  const [submitedAns, setSubmitedAns] = useState(null);

  const handleAnswerSelect = (index) => {
    // Get the selected choice based on its index
    const selectedChoice = questionDataApi.choices[index];

    // Filter out all correct answers to count them
    const correctAnswers = questionDataApi.choices.filter(
      (choice) => choice.is_correct
    );

    // Get the number of correct answers
    const numCorrectAnswers = correctAnswers.length;

    // Check if the selected choice is correct
    const isCorrectChoice = selectedChoice.is_correct;

    // If the choice is correct and the user has already selected the maximum number of correct answers, do nothing
    if (isCorrectChoice && selectedAnswer.length >= numCorrectAnswers) {
      console.log(
        `You can only select up to ${numCorrectAnswers} correct answers.`
      );
      return; // Prevent selection if there are already `numCorrectAnswers` correct answers selected
    }

    setSelectedAnswer((prevSelectedAnswers) => {
      // If the answer is already selected, deselect it
      if (prevSelectedAnswers.includes(index)) {
        return prevSelectedAnswers.filter(
          (selectedIndex) => selectedIndex !== index
        );
      } else {
        // If it's not selected, select it (if it's correct or if we haven't hit the limit yet)
        if (isCorrectChoice || prevSelectedAnswers.length < numCorrectAnswers) {
          return [...prevSelectedAnswers, index];
        } else {
          console.log(
            `You can only select up to ${numCorrectAnswers} correct answers.`
          );
          return prevSelectedAnswers; // Prevent selecting if already reached the max correct selections
        }
      }
    });

    console.log(`Selected answer: ${index}`, questionDataApi.choices[index]);
  };

  const handleSubmit = async () => {
    const correctChoices = questionDataApi.choices.filter(
      (choice) => choice.is_correct
    ); // Filter out all correct choices
    const choiceLabels = ["A", "B", "C", "D"];

    // Find the correct answers and their corresponding labels
    const correctAnswerLabels = correctChoices.map(
      (correctChoice) =>
        choiceLabels[questionDataApi.choices.indexOf(correctChoice)]
    );

    // Map the selected answers to their labels
    const selectedAnswerLabels = selectedAnswer.map(
      (index) => choiceLabels[index]
    );

    const sortedCorrectAnswerLabels = correctAnswerLabels.sort();
    const sortedSelectedAnswerLabels = selectedAnswerLabels.sort();
    console.log("Correct answer labels:", correctAnswerLabels);
    console.log("Selected answer labels:", selectedAnswerLabels);

    if (
      JSON.stringify(sortedCorrectAnswerLabels) ===
      JSON.stringify(sortedSelectedAnswerLabels)
    ) {
      console.log("Correct Answer Selected!", selectedAnswerLabels);
      setSubmitedAns(true);
    } else {
      setSubmitedAns(false);
      console.log("Incorrect Answer Selected!");
    }
    const payload = {
      questionId: id,
      answers: selectedAnswerLabels,
      correctAnswers: correctAnswerLabels,
    };
    console.log(payload, "answer");
    try {
      const answer = await postApi(API_PATH.STUDENTS.SUBMIT_ANS, payload);
      if (answer.status === 200) {
        console.log(answer.data.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    return () => {
      if (recordedAudio?.url) {
        URL.revokeObjectURL(recordedAudio.url);
      }
    };
  }, []);

  const handleRecordingComplete = (recording) => {
    console.log("Recording completed:", recording);
    setRecordedAudio(recording);

    if (recording.scores) {
      setScores({
        content: recording.scores.content,
        pronunciation: recording.scores.pronunciation,
        fluency: recording.scores.fluency,
        overall: Math.round(
          (recording.scores.content +
            recording.scores.pronunciation +
            recording.scores.fluency) /
            3
        ),
      });
    }
  };

  const handleNext = async () => {
    if (currentQuestion === questionData.totalQuestions) {
      alert("Congratulations You have completed all questions!");
      return;
    } else {
      setCurrentQuestion(currentQuestion + 1);
      setCurrentIndex(currentIndex + 1);
      getQuestionBtID(questionData.questions[currentIndex + 1].id);
      setSubmitedAns(null);
      setSelectedAnswer([]);
    }
  };

  const handlePrev = async () => {
    if (currentQuestion > 1) {
      setCurrentQuestion(currentQuestion - 1);
      setCurrentIndex(currentIndex - 1);
      setQuestionId(questionData.questions[currentIndex - 1].id);
      getQuestionBtID(questionData.questions[currentIndex - 1].id);
      setSubmitedAns(null);
      setSelectedAnswer([]);
    }
  };

  return (
    <Box sx={{ bgcolor: "#1a1a2e", minHeight: "100vh", p: 2 }}>
      {/* Fixed Header */}
      {/* <p>Current ID: {questionData.questions[currentIndex].id}</p> */}
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          zIndex: 1000,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              p: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
            }}
          >
            <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
              <i className="fas fa-arrow-left" />
            </IconButton>

            <Typography variant="h6" sx={{ color: "#fff", ml: "20px" }}>
              Multiple Answers
            </Typography>

            <Box
              sx={{ display: "flex", alignItems: "center", gap: 1, ml: "auto" }}
            >
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {currentQuestion}/{questionData.totalQuestions}
              </Typography>
              <IconButton sx={{ color: "#fff" }} onClick={() => handlePrev()}>
                <i className="fas fa-chevron-left" />
              </IconButton>
              <IconButton
                sx={{ color: "#fff" }}
                onClick={() => setOpenSearchModal(true)}
              >
                <i className="fas fa-search" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }}>
                <i className="fas fa-rotate" />
              </IconButton>
              <IconButton sx={{ color: "#fff" }} onClick={() => handleNext()}>
                <i className="fas fa-chevron-right" />
              </IconButton>
            </Box>
          </Box>
        </Container>
      </Box>

      <Container
        maxWidth="lg"
        sx={{
          pt: "80px",
          p: 2,
        }}
      >
        {/* Added Instructions Text */}
        <Typography
          sx={{
            color: "#fff",
            mt: 8,
            mb: 2,
            fontSize: "15px",
            fontStyle: "italic",
          }}
        >
         Listen to the recording and answer the question by selecting all the correct responses. You will need to select more than one response.
        </Typography>

        {/* Instructions */}
        <Paper sx={{ p: 1, my: 2, color: "#fff", backgroundColor: "#1A1A2E" }}>
          <Typography>{questionDataApi.question_text}</Typography>
        </Paper>

        {/* <RepeatParagraphAloud /> */}
        <RepeatParagraphAloud
          audioText={JSON.stringify(questionDataApi.audio_text)}
        />

        {/* Answer Options */}
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {questionDataApi.choices?.map((choice, index) => {
            // Check if this option is selected by the user
            const isSelected = selectedAnswer.includes(index);
            const isCorrect = choice.is_correct;

            // Determine the background color based on the state
            const backgroundColor =
              submitedAns === null // Before submission
                ? isSelected // If this option is selected
                  ? "#00BFA6" // Keep selected option highlighted (light blue)
                  : "rgba(52, 58, 64, 0.8)" // Default neutral color for non-selected answers
                : // After submission, update colors based on correctness
                isSelected // If this option is selected by the user
                ? isCorrect
                  ? "green" // Correct selected answer: green
                  : "red" // Wrong selected answer: red
                : isCorrect // Highlight correct answer
                ? "green" // Correct answers should always turn green
                : "rgba(52, 58, 64, 0.8)"; // Neutral color for other unselected answers

            return (
              <Box
                key={index}
                onClick={() =>{submitedAns === null && handleAnswerSelect(index)}} // Handle the user selection
                sx={{
                  bgcolor: backgroundColor,
                  p: 2.5,
                  borderRadius: "8px",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  transition: "all 0.2s ease",
                  "&:hover": {
                    // Disable hover effect after submission
                    bgcolor:
                      submitedAns === null
                        ? isSelected // If option is selected, it will remain highlighted on hover
                          ? "#00BFA6" // Keep selected option hover color same
                          : "rgba(52, 58, 64, 0.9)" // Darken other options on hover
                        : isSelected // If this option is selected by the user
                        ? isCorrect
                          ? "green" // Correct answer: green
                          : "red" // Wrong answer: red
                        : isCorrect // Highlight correct answer
                        ? "green" // Correct answer highlighted in green
                        :"rgba(52, 58, 64, 0.9)" // After submission, revert to the neutral color
                  },
                }}
              >
                <Typography sx={{ fontSize: "14px", color: "#fff" }}>
                  {choice.choice_text}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </Container>

      {/* Fixed Footer with Background */}
      <Box
        sx={{
          // position: "fixed",
          // bottom: 0,
          // left: 0,
          // right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        {/* <Button
          variant="contained"
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button> */}

        <Button
          onClick={handleSubmit}
          sx={{
            mt: 3,
            bgcolor: "#00BFA6",
            color: "#fff",
            borderRadius: "8px",
            p: 2,
            "&:hover": { bgcolor: "#009e8b" },
          }}
        >
          Submit Answer
        </Button>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {100} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default MultipleAnswerListening;
