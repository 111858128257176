import React, { useEffect, useState } from "react";
import { Tabs, Tab, Card, Typography, Box } from "@mui/material";
import CampaignIcon from "@mui/icons-material/Campaign";
import HearingIcon from "@mui/icons-material/Hearing";
import EditIcon from "@mui/icons-material/Edit";
import AutoStoriesIcon from "@mui/icons-material/AutoStories";
import { Chip } from "@mui/material";

import "../../style.css";

import { Link, useLocation, useNavigate } from "react-router-dom";

import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function Dashboard() {
  const [activeTab, setActiveTab] = useState(0); 
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const tabParam = params.get("tab");

  useEffect(() => {
    // Get the tab parameter from URL

    // Map tab names to their index
    const tabMapping = {
      Speaking: 0,
      Writing: 1,
      Reading: 2,
      Listening: 3,
    }; 
    if(tabParam){ 
      setActiveTab(tabMapping[tabParam]);
    }
    // Set active tab if valid parameter exists
    if (tabParam && tabMapping.hasOwnProperty(tabParam.toLowerCase())) {
      console.log(tabParam,"tabParam1")
      setActiveTab(tabMapping[tabParam.toLowerCase()]); 
    }
  }, [tabParam]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const tabNames = ["Speaking", "Writing", "Reading", "Listening"];
    navigate(`?tab=${tabNames[newValue]}`);
  };

  const handleCardClick = (path) => {
    navigate(path);
  };

  // Modify the handleCardClick to navigate instead of alerting
  const handleCardClickpath = (category, subCategory) => {
    const path = `/${category}/${subCategory}`;
    navigate(path); // Use navigate to go to the desired page
  };

  // Speaking section items
  const speakingItems = [
    {
      label: "Read Aloud",
      path: "/speaking/RAQ",
      chips: ["New", "Prediction", "Exam", "VIP"],
    },

    {
      label: "Repeat Sentence",
      path: "/speaking/RS",
      message: "Repeat Sentence card clicked!",
      chips: ["New", "VIP"],
    },
    {
      label: "Describe Image",
      message: "Describe Image card clicked!",
      path: "/speaking/DIQ",
    },
    { label: "Retell Lecture", message: "Re-tell Lecture card clicked!" },
    {
      label: "Short Answer",
      message: "Short Answer card clicked!",
      path: "/speaking/SAQ",
    },
    {
      label: "Respond to Situation",
      message: "Respond to Situation card clicked!",
    },
  ];

  // Writing section items
  const writingItems = [
    { label: "Essay", message: "Essay card clicked!", path: "/writing/ESSAY" },
    {
      label: "Summarize Text Acedimic",
      message: "Summarize Text card clicked!",
    },
    { label: "Write Email", message: "Write Email card clicked!" },
    { label: "Summarize text Core", message: "Summarize text card clicked!" },
  ];

  // Reading section items
  const readingItems = [
    { label: "Single Answer", message: "Single Answer card clicked!" },
    { label: "Multiple Answer", message: "Multiple Answer card clicked!" , path: "/reading/MA",},
    {
      label: "Reorder Paragraph",
      message: "Reorder Paragraph card clicked!",
      path: "/reading/ROP",
    },
    { label: "Fill in Blanks", path: "/reading/FITB" },
    {
      label: "R/W Fill in Blanks",
      message: "R/W Fill in Blanks card clicked!",
      path: "/reading/RWF",
    },
  ];

  // Listening section items
  const listeningItems = [
    {
      label: "Single Answer",
      message: "Single Answer card clicked!",
      path: "/listening/SMCQ",
    },
    {
      label: "Multiple Answer",
      message: "Multiple Answer card clicked!",
      path: "/listening/MCQ",
    },
    {
      label: "Write from Dictation",
      message: "Write from Dictation card clicked!",
      path: "/listening/WFD",
    },
    {
      label: "Highlight Summary Academic",
      message: "Highlight Summary card clicked!",
      path: "/listening/HSQ",
    },
    {
      label: "Incorrect Words",
      message: "Incorrect Words card clicked!",
      path: "/listening/IWQ",
    },
    {
      label: "Fill in the blanks",
      message: "Fill in the blanks card clicked!",
      path: "/listening/FTBQ",
    },
    {
      label: "Summarize Text Academic",
      message: "Summarize Text Academic card clicked!",
    },
    {
      label: "Missing Word",
      message: "Missing Word card clicked!",
      path: "/listening/MWQ",
    },
    {
      label: "Summarize Text Core",
      message: "Summarize Text Core card clicked!",
    },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          indicatorColor="none"
        >
          <Tab
            className="tabresponsive"
            icon={
              <CampaignIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Speaking"
            style={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              backgroundColor: "#FDC4B6",
              marginRight: "10px",
            }}
          />
          <Tab
            className="tabresponsive"
            icon={
              <EditIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Writing"
            style={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              backgroundColor: "#B5F0FA",
              marginRight: "10px",
            }}
          />
          <Tab
            className="tabresponsive"
            icon={
              <AutoStoriesIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Reading"
            style={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              backgroundColor: "#2DCE89",
              marginRight: "10px",
            }}
          />
          <Tab
            className="tabresponsive"
            icon={
              <HearingIcon
                sx={{
                  backgroundColor: "white",
                  color: "white",
                  borderRadius: "5px",
                  width: "20%",
                  height: "30px",
                }}
              />
            }
            label="Listening"
            style={{
              minWidth: 80,
              textTransform: "none",
              height: 80,
              fontWeight: "500",
              fontSize: "20px",
              backgroundColor: "#ACB0B9",
            }}
          />
        </Tabs>

        {/* Tab content */}
        <Box sx={{ paddingTop: "22px" }}>
          {activeTab === 0 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Speaking ( AI Scoring )</Typography>
              <Box className="scrollable-section">
                {speakingItems.map((item, index) => (
                  <Link
                    to={`/questions?category=Speaking&subcategory=${item.label}`}
                  >
                    <Card
                      className="innersections"
                      key={index}
                      sx={{
                        backgroundColor: "#F0F8FF",
                        marginTop: "15px",
                        cursor: "pointer",
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Card>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 1 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Writing ( AI Scoring )</Typography>
              <Box className="scrollable-section">
                {writingItems.map((item, index) => (
                  <Link
                    to={`/questions?category=Writing&subcategory=${item.label}`}
                  >
                    <Card
                      className="innersections"
                      key={index}
                      sx={{
                        backgroundColor: "#F0F8FF",
                        marginTop: "15px",
                        cursor: "pointer",
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Card>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 2 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Reading</Typography>
              <Box className="scrollable-section">
                {readingItems.map((item, index) => (
                  <Link
                    to={`/questions?category=Reading&subcategory=${item.label}`}
                  >
                    <Card
                      className="innersections"
                      key={index}
                      sx={{
                        backgroundColor: "#F0F8FF",
                        marginTop: "15px",
                        cursor: "pointer",
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Card>
                  </Link>
                ))}
              </Box>
            </Card>
          )}

          {activeTab === 3 && (
            <Card sx={{ marginX: "0px", padding: "16px" }}>
              <Typography variant="h5">Listening</Typography>
              <Box className="scrollable-section">
                {listeningItems.map((item, index) => (
                  <Link
                    to={`/questions?category=Listening&subcategory=${item.label}`}
                  >
                    <Card
                      className="innersections"
                      key={index}
                      sx={{
                        backgroundColor: "#F0F8FF",
                        marginTop: "15px",
                        cursor: "pointer",
                        padding: "16px",
                      }}
                    >
                      <Typography variant="body1">{item.label}</Typography>
                    </Card>
                  </Link>
                ))}
              </Box>
            </Card>
          )}
        </Box>
      </Box>
    </DashboardLayout>
  );
}

export default Dashboard;
