import ReadAloudComponent from "layouts/dashboard/components/readaloud";
import ReadAloud from "../../layouts/speaking/RAQ/answers";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { postApi } from "services/axiosInstance";
import { API_PATH } from "services/apipath";
import RepeatSentenceAnswers from "../../layouts/speaking/RS/answers";
import ImageDescription from "layouts/speaking/DIQ/answers";
import ShortAnswer from "layouts/speaking/SAQ/answers";
import RespondToSituation from "layouts/speaking/RTS/answers";
import RetellLecture from "layouts/speaking/RL/answers";
import EssayAnswers from "layouts/writing/ESSAY/answers";
import SummarizeTextAcedimic from "layouts/writing/STA/answers";
import WriteEmail from "layouts/writing/WE/answers";
import SummarizeTextCore from "layouts/writing/STC/answers";
import ReorderParagraphs from "layouts/reading/ROP/answers";
import FillInBlanks from "layouts/reading/FITB/answers";
import FillInBlanksSelect from "layouts/reading/RWF/answers";
import Answers from "layouts/listening/SMCQ/answers";
import SingleAnswer from "layouts/listening/SMCQ/answers";
import DictationAnswers from "layouts/listening/WFD/dictationanswers";
import HighlightSummaryAnswer from "layouts/listening/HSQ/answers";
import IncorrectWords from "layouts/listening/IWQ/answers";
import FillInBlanksAnswer from "layouts/listening/FTBQ/answers";
import MissingWordsAnswer from "layouts/listening/MWQ/answers";
import MissingWordAnswer from "layouts/listening/MWQ/answers";
import SummarizeTextAcademic from "layouts/listening/STA/summarizetextacademic";
import SingleAnswerListening from "../../layouts/listening/SA/answers";
import SingleAnswerReading from "layouts/reading/SA/answers";
import MultipleAnswerListening from "layouts/listening/MA/answers";
import MultipleAnswerReading from "layouts/reading/MA/answers";

const QuestionRender = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const Category = params.get("category");
  const subCategory = params.get("subcategory");
  const [questionDta, setQuestionsDta] = useState([]);

  const GetIds = async () => {
    try {
      const res = await postApi(
        `${API_PATH.STUDENTS.GET_QUESTIONS_IDS_BY_CATEGORY}/${Category}`,
        {
          subcategory: subCategory,
        }
      );
      if (res.status === 200) {
        setQuestionsDta(res.data.data);
      }
    } catch (error) { }
  };

  useEffect(() => {
    GetIds();
  }, []);

  const PickPage = () => {
    // Define the mapping of category-subcategory to components
    const categorySubcategoryMap = {
      "Speaking": {
        "Read Aloud": <ReadAloud questionData={questionDta} />,
        "Repeat Sentence": <RepeatSentenceAnswers questionData={questionDta} />,
        "Describe Image": <ImageDescription questionData={questionDta} />,
        "Retell Lecture": <RetellLecture questionData={questionDta} />,
        "Short Answer": <ShortAnswer questionData={questionDta} />,
        "Respond to Situation": <RespondToSituation questionData={questionDta} />,
      },
      "Writing": {
        "Essay": <EssayAnswers questionData={questionDta} />,
        "Summarize Text Acedimic": <SummarizeTextAcedimic questionData={questionDta} />,
        "Write Email": <WriteEmail questionData={questionDta} />,
        "Summarize Text Core": <SummarizeTextCore questionData={questionDta} />,
      },
      "Reading": {
        "Single Answer": <SingleAnswerReading questionData={questionDta} />,
        "Multiple Answer": <MultipleAnswerReading questionData={questionDta} />,
        "Reorder Paragraph": <ReorderParagraphs questionData={questionDta} />,
        "Fill in Blanks": <FillInBlanks questionData={questionDta} />,
        "R/W Fill in Blanks": <FillInBlanksSelect questionData={questionDta} />,
      },
      "Listening": {
        "Single Answer": <SingleAnswerListening questionData={questionDta} />,
        "Multiple Answer": <MultipleAnswerListening questionData={questionDta} />,
        "Write from Dictation": <DictationAnswers questionData={questionDta} />,
        "Highlight Summary": <HighlightSummaryAnswer questionData={questionDta} />,
        "Incorrect Words": <IncorrectWords questionData={questionDta} />,
        "Fill in Blanks": <FillInBlanksAnswer questionData={questionDta} />,
        "Missing Word": <MissingWordAnswer questionData={questionDta} />,
        "Summarize Text Academic": <SummarizeTextAcademic questionData={questionDta} />,
        "Summarize Text Core": <SummarizeTextCore questionData={questionDta} />,
      }
    };
  
    // Check if category and subCategory exist in the map
    const categoryMap = categorySubcategoryMap[Category];
    if (categoryMap) {
      const component = categoryMap[subCategory];
      if (component) {
        return component;
      }
    }
  
    // Default case when no match is found
    return <div>No component for this category</div>;
  };
  

  return <>{PickPage()}</>;
};

export default QuestionRender;
