// @mui material components
import Grid from "@mui/material/Grid";

//  Dashboard React components
import SoftBox from "components/SoftBox";


//  Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";

import "../../style.css";

import BillingInformation from "layouts/history/components/BillingInformation";
function History() {
  return (
    <DashboardLayout>

      <SoftBox mt={4}>
        <SoftBox>
          <Grid container>
            <Grid item xs={12} md={12}>
              <BillingInformation />
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </DashboardLayout>
  );
}

export default History;
