import React from 'react'
import QuestionsPage from './questions'


const ReorderParagraphs = () => {
  return (
    <div>
      <QuestionsPage />
    </div>
  )
}

export default ReorderParagraphs