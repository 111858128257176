import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Button,
  TextField,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import AudioPlayer from "components/AudioPlayer";
import { useNavigate } from "react-router-dom";

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

function FillInBlanksAnswer() {
  const navigate = useNavigate();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [answers, setAnswers] = useState({
    blank1: "",
    blank2: "",
    blank3: "",
    blank4: "",
    blank5: "",
  });

  const handleAnswerChange = (blank) => (event) => {
    setAnswers({
      ...answers,
      [blank]: event.target.value,
    });
  };

  return (
    <Box sx={{ bgcolor: "#1A1B2E", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            Fill in the blanks
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Typography>1/296</Typography>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => setOpenSearchModal(true)}
          >
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-chevron-right" />
          </IconButton>
        </Box>
      </Box>

      {/* Instructions */}
      <Box sx={{ px: 2, py: 3 }}>
        <Typography
          sx={{ color: "rgba(255,255,255,0.8)", mb: 2, fontStyle: "italic" }}
        >
          You will hear a recording. Type the missing words in each blank.
        </Typography>

        {/* Audio Player */}
        <AudioPlayer />

        {/* Text Content with Blanks */}
        <Box sx={{ mt: 4, lineHeight: 1.8, fontSize: "16px" }}>
          <Typography component="div" sx={{ color: "rgba(255,255,255,0.9)" }}>
            We are trying to understand the
            <TextField
              variant="outlined"
              size="small"
              value={answers.blank1}
              onChange={handleAnswerChange("blank1")}
              sx={{
                mx: 1,
                width: "150px",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.3)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00A693",
                  },
                },
              }}
            />
            of one of our closest living relatives, which is the orangutan, and
            also the locomotion of all of the apes and the common ancestor of
            humans and the other apes. And in that area, we have had a big
            problem traditionally, and that we know a lot about how they move
            around the forest. I've been out to the forest and spent a year
            recording the
            <TextField
              variant="outlined"
              size="small"
              value={answers.blank2}
              onChange={handleAnswerChange("blank2")}
              sx={{
                mx: 1,
                width: "150px",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.3)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00A693",
                  },
                },
              }}
            />
            types of locomotion they use, but we have no idea about the
            <TextField
              variant="outlined"
              size="small"
              value={answers.blank3}
              onChange={handleAnswerChange("blank3")}
              sx={{
                mx: 1,
                width: "150px",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.3)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00A693",
                  },
                },
              }}
            />
            cost of how they move around the forest and the solutions that they
            find to problems of moving around the canopy. And what we're doing
            here is using the park or
            <TextField
              variant="outlined"
              size="small"
              value={answers.blank4}
              onChange={handleAnswerChange("blank4")}
              sx={{
                mx: 1,
                width: "150px",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.3)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00A693",
                  },
                },
              }}
            />
            as an analogy for a large bodied ape moving around a complex
            environment and getting them to move around in the course that we've
            made that they've never seen before. And we're going to record their
            energetic
            <TextField
              variant="outlined"
              size="small"
              value={answers.blank5}
              onChange={handleAnswerChange("blank5")}
              sx={{
                mx: 1,
                width: "150px",
                "& .MuiOutlinedInput-root": {
                  color: "#fff",
                  "& fieldset": {
                    borderColor: "rgba(255,255,255,0.3)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "#00A693",
                  },
                },
              }}
            />
            while they're doing it.
          </Typography>
        </Box>

        {/* Fixed Footer with Background */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "#1A1B2E",
            borderTop: "1px solid rgba(255,255,255,0.1)",
            display: "flex",
            justifyContent: "center",
            p: 2,
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#00A693",
              "&:hover": {
                bgcolor: "#009688",
              },
              textTransform: "none",
              px: 4,
              py: 1,
              fontSize: "16px",
              borderRadius: "4px",
              minWidth: "120px",
              color: "#fff",
            }}
          >
            Submit
          </Button>
        </Box>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {79} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default FillInBlanksAnswer;
