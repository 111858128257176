import React from 'react'
import QuestionsPage from './questions'


const FillTheQuestions = () => {
  return (
    <div>
      <QuestionsPage />
    </div>
  )
}

export default FillTheQuestions