import React, { useState } from "react";
import {
  Box,
  TextField,
  Typography,
  IconButton,
  Button,
  Container,
  Modal,
  Select,
  MenuItem,
} from "@mui/material";
import AudioPlayer from "components/AudioPlayer";
import { useNavigate } from "react-router-dom";

function TextAreaCard({ questionTitle, searchItems, path }) {
  const navigate = useNavigate();
  const [text, setText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [openSearchModal, setOpenSearchModal] = useState(false);

  const handleTextChange = (e) => {
    const newText = e.target.value;
    setText(newText);
    const words = newText.trim().split(/\s+/).filter(Boolean).length;
    setWordCount(words);
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Box sx={{ bgcolor: "#1A1F2E", minHeight: "100vh" }}>
      {/* Header */}
      <Box
        sx={{
          bgcolor: "#1A1B2E",
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          borderBottom: "1px solid rgba(255,255,255,0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1000,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <IconButton
            sx={{ color: "#fff" }}
            onClick={() => navigate(`${path}`)}
          >
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography variant="h5" sx={{ color: "#fff" }}>
            {questionTitle}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ display: "flex", gap: 1 }}>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-chevron-left" />
            </IconButton>
            <IconButton
              sx={{ color: "#fff" }}
              onClick={() => setOpenSearchModal(true)}
            >
              <i className="fas fa-search" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-rotate" />
            </IconButton>
            <IconButton sx={{ color: "#fff" }}>
              <i className="fas fa-chevron-right" />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Container maxWidth="md" sx={{ my: 4, pb: "80px" }}>
        <Typography
          sx={{
            color: "#fff",
            fontStyle: "italic",
            mb: 3,
          }}
        >
          You will hear a sentence. Type the sentence in the box below exactly
          as you hear it. 
        </Typography>

        {/* Audio Player */}
        <AudioPlayer />

        {/* Answer Box */}
        <Box
          sx={{
            bgcolor: "#fff",
            borderRadius: 1,
            p: 3,
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              width: "100%",
            }}
          >
            <Typography>Answer</Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>words: {wordCount}</Typography>
              <IconButton onClick={handleCopy} size="small">
                <i className="fas fa-copy" />
              </IconButton>
            </Box>
          </Box>

          <TextField
            fullWidth
            multiline
            rows={6}
            value={text}
            placeholder="Type the answer here"
            onChange={handleTextChange}
            sx={{
              // display: "block",
              width: "100% !important", // Added this
              "& .MuiOutlinedInput-root": {
                width: "100%",
                backgroundColor: "#E8E9F3 !important",
                "&:hover": {
                  backgroundColor: "#E8E9F3 !important",
                },
                "& fieldset": {
                  borderColor: "#E0E0E0",
                },
              },
              "& .MuiOutlinedInput-input": {
                backgroundColor: "#E8E9F3 !important",
                width: "100% !important",
                wordBreak: "break-word",
              },
              "& .MuiInputBase-multiline": {
                width: "100% !important",
                padding: "14px",
              },
              "& .MuiInputBase-root": {
                // Added this block
                width: "100% !important",
              },
            }}
          />
        </Box>

        {/* Fixed Footer with Background */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: 0,
            right: 0,
            bgcolor: "#1A1B2E",
            borderTop: "1px solid rgba(255,255,255,0.1)",
            display: "flex",
            justifyContent: "center",
            p: 2,
            zIndex: 1000,
          }}
        >
          <Button
            variant="contained"
            sx={{
              bgcolor: "#00A693",
              "&:hover": {
                bgcolor: "#009688",
              },
              textTransform: "none",
              px: 4,
              py: 1,
              fontSize: "16px",
              borderRadius: "4px",
              minWidth: "120px",
              color: "#fff",
            }}
          >
            Submit
          </Button>
        </Box>
      </Container>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  gap: 1,
                }}
              >
                <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                  {79} Qs
                </Typography>
              </Box>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default TextAreaCard;
