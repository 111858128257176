// ReorderParagraphs.jsx
import React, { useState } from 'react';
import { Box, Typography, IconButton, Button, Modal, Select, MenuItem } from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';

const searchItems = [
  { id: "SA #82", attempted: 0 },
  { id: "SA #81", attempted: 0 },
  { id: "SA #80", attempted: 0 },
  { id: "SA #78", attempted: 0 },
  { id: "SA #77", attempted: 0 },
];

const paragraphs = [
  {
    id: 'p1',
    content: 'Cosmologists use observations, theories, and mathematical models to understand cosmic phenomena.'
  },
  {
    id: 'p2',
    content: 'It explores fundamental questions about the cosmos, such as its size and age.'
  },
  {
    id: 'p3',
    content: "Cosmology seeks to unravel the mysteries of the universe's past, present, and future."
  },
  {
    id: 'p4',
    content: 'Topics include the Big Bang theory, dark matter, dark energy, and cosmic inflation.'
  },
  {
    id: 'p5',
    content: 'Cosmology: study of the origin, evolution, and structure of the universe.'
  },
];

function ReorderParagraphs() {
  const navigate = useNavigate();
  const [openSearchModal, setOpenSearchModal] = useState(false);
  const [sourceParagraphs, setSourceParagraphs] = useState(paragraphs);
  const [targetParagraphs, setTargetParagraphs] = useState([]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      // Reordering within the same list
      const items = Array.from(
        source.droppableId === 'source' ? sourceParagraphs : targetParagraphs
      );
      const [reorderedItem] = items.splice(source.index, 1);
      items.splice(destination.index, 0, reorderedItem);

      if (source.droppableId === 'source') {
        setSourceParagraphs(items);
      } else {
        setTargetParagraphs(items);
      }
    } else {
      // Moving between lists
      const sourceItems = Array.from(
        source.droppableId === 'source' ? sourceParagraphs : targetParagraphs
      );
      const destItems = Array.from(
        destination.droppableId === 'source' ? sourceParagraphs : targetParagraphs
      );
      const [movedItem] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, movedItem);

      setSourceParagraphs(source.droppableId === 'source' ? sourceItems : destItems);
      setTargetParagraphs(source.droppableId === 'source' ? destItems : sourceItems);
    }
  };

  return (
    <Box sx={{ bgcolor: "#1E2029", minHeight: "100vh", color: "#fff" }}>
      {/* Header */}
      <Box sx={{
           bgcolor: "#1A1B2E",
           p: 2,
           display: "flex",
           alignItems: "center",
           justifyContent: "space-between",
           borderBottom: "1px solid rgba(255,255,255,0.1)",
           position: "sticky",
           top: 0,
           zIndex: 1000,
      }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton sx={{ color: "#fff" }} onClick={() => navigate(-1)}>
            <i className="fas fa-arrow-left" />
          </IconButton>
          <Typography sx={{ ml: 2, fontSize: "20px" }}>
            Reorder Paragraphs
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button
            variant="outlined"
            size="small"
            sx={{
              color: '#ff4444',
              borderColor: '#ff4444',
              '&:hover': {
                borderColor: '#ff6666',
                bgcolor: 'rgba(255,68,68,0.1)'
              }
            }}
          >
            Report Question
          </Button>
          <IconButton sx={{ color: "#fff" }} onClick={() => setOpenSearchModal(true)}>
            <i className="fas fa-search" />
          </IconButton>
          <IconButton sx={{ color: "#fff" }}>
            <i className="fas fa-rotate" />
          </IconButton>
        </Box>
      </Box>

      {/* Main Content */}
      <Box sx={{ pb: "80px", pt: 4, overflowY: "auto", px: 3 }}>
        <Typography sx={{ color: "rgba(255,255,255,0.8)", mb: 2, fontStyle: "italic" }}>
          The text boxes in the left panel have been placed in a random order. Restore the original order by dragging the text boxes from the left panel to the right panel.
        </Typography>

        <DragDropContext onDragEnd={handleDragEnd}>
          <Box sx={{ display: 'flex', gap: 4, mt: 4 }}>
            {/* Source Panel */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ mb: 2, fontWeight: 'bold' }}>Source</Typography>
              <Droppable droppableId="source">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ 
                      minHeight: 400,
                      bgcolor: '#ffffff05',
                      borderRadius: 1,
                      p: 2
                    }}
                  >
                    {sourceParagraphs.map((paragraph, index) => (
                      <Draggable key={paragraph.id} draggableId={paragraph.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              p: 2,
                              mb: 2,
                              bgcolor: snapshot.isDragging ? '#2F315A' : '#262842',
                              borderRadius: 1,
                              transition: 'background-color 0.2s ease',
                              '&:hover': { bgcolor: '#2F315A' }
                            }}
                          >
                            <Typography>{paragraph.content}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>

            {/* Target Panel */}
            <Box sx={{ flex: 1 }}>
              <Typography sx={{ mb: 2, fontWeight: 'bold' }}>Target</Typography>
              <Droppable droppableId="target">
                {(provided) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    sx={{ 
                      minHeight: 400,
                      border: '1px solid rgba(255,255,255,0.1)',
                      borderRadius: 1,
                      p: 2
                    }}
                  >
                    {targetParagraphs.map((paragraph, index) => (
                      <Draggable key={paragraph.id} draggableId={paragraph.id} index={index}>
                        {(provided, snapshot) => (
                          <Box
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={{
                              p: 2,
                              mb: 2,
                              bgcolor: snapshot.isDragging ? '#2F315A' : '#262842',
                              borderRadius: 1,
                              transition: 'background-color 0.2s ease',
                              '&:hover': { bgcolor: '#2F315A' }
                            }}
                          >
                            <Typography>{paragraph.content}</Typography>
                          </Box>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </Box>
          </Box>
        </DragDropContext>

        {/* Fixed Footer with Background */}
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          right: 0,
          bgcolor: "#1A1B2E",
          borderTop: "1px solid rgba(255,255,255,0.1)",
          display: "flex",
          justifyContent: "center",
          p: 2,
          zIndex: 1000,
        }}
      >
        <Button
          variant="contained"
          sx={{
            bgcolor: "#00A693",
            "&:hover": {
              bgcolor: "#009688",
            },
            textTransform: "none",
            px: 4,
            py: 1,
            fontSize: "16px",
            borderRadius: "4px",
            minWidth: "120px",
            color: "#fff",
          }}
        >
          Submit
        </Button>
      </Box>
      </Box>

      {/* Search Modal */}
      <Modal
        open={openSearchModal}
        onClose={() => setOpenSearchModal(false)}
        aria-labelledby="search-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "#1A1B2E",
            borderRadius: "8px",
            p: 3,
            outline: "none",
            maxHeight: "80vh",
            overflow: "auto",
          }}
        >
          {/* Modal Header */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 3,
              borderBottom: "1px solid rgba(255, 255, 255, 0.1)",
              pb: 2,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Select
                value="All"
                size="small"
                sx={{
                  color: "#fff",
                  height: "32px",
                  minWidth: "100px",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.3)",
                    borderRadius: "4px",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "rgba(255,255,255,0.5)",
                  },
                }}
              >
                <MenuItem value="All">All</MenuItem>
              </Select>
              <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                {79} Qs
              </Typography>
            </Box>
            <IconButton
              onClick={() => setOpenSearchModal(false)}
              sx={{
                color: "#fff",
                p: 1,
                "&:hover": { bgcolor: "rgba(255,255,255,0.1)" },
              }}
            >
              <i className="fas fa-times" />
            </IconButton>
          </Box>

          {/* Search Items */}
          <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {searchItems.map((item, index) => (
              <Box
                key={index}
                sx={{
                  bgcolor: "#262842",
                  borderRadius: "8px",
                  p: 2,
                  cursor: "pointer",
                  "&:hover": {
                    bgcolor: "#2F315A",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography sx={{ color: "#fff", fontSize: "14px" }}>
                    {index + 1}. {item.id}
                  </Typography>
                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      New
                    </Box>
                    <Box
                      sx={{
                        bgcolor: "#FFC107",
                        px: 1,
                        borderRadius: "4px",
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      VIP
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography
                    sx={{
                      color: "rgba(255,255,255,0.6)",
                      fontSize: "12px",
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    Attempted: {item.attempted}
                  </Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default ReorderParagraphs;