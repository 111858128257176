import React, { useState } from "react";
import {
  Box,
  Typography,
  IconButton,
  Select,
  MenuItem,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { QuestionCard } from "components/QuestionCard/QuestionCard";
import Pagination from "components/Pagination/Pagination";

const questions = [
  { id: "MA #100", attempts: 1, completed: true },
  { id: "MA #99", attempts: 0, completed: false },
  { id: "MA #98", attempts: 0, completed: false },
  { id: "MA #97", attempts: 0, completed: false },
  { id: "MA #96", attempts: 0, completed: false },
  { id: "MA #95", attempts: 1, completed: false },
  { id: "MA #94", attempts: 0, completed: false },
  { id: "MA #93", attempts: 0, completed: false },
  { id: "MA #92", attempts: 1, completed: false },
  { id: "MA #91", attempts: 0, completed: false },
  { id: "MA #90", attempts: 0, completed: false },
  { id: "MA #89", attempts: 0, completed: false },
];

function Questions() {
  const navigate = useNavigate();
  const [filterType, setFilterType] = useState("all");

  // Pagination logic
  const ITEMS_PER_PAGE = 5;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(questions.length / ITEMS_PER_PAGE);

  const handleFilterChange = (event) => {
    setFilterType(event.target.value);
    setCurrentPage(1);
  };

  const getFilteredQuestions = () => {
    switch (filterType) {
      case "attempted":
        return questions.filter(q => q.attempts > 0);
      case "unattempted":
        return questions.filter(q => q.attempts === 0);
      default:
        return questions;
    }
  };

  // Get current page questions
  const getCurrentPageQuestions = () => {
    const filteredQuestions = getFilteredQuestions();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filteredQuestions.slice(startIndex, endIndex);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    window.scrollTo(0, 0);
  };

  const handleNewClick = (questionId) => {
    const id = questionId.split("#")[1].trim();
    navigate(`/listening/HSQ/${id}`);
  };

  const handleClose = () => {
    navigate('/dashboard?tab=listening'); 
  };

  return (
    <Box sx={{ bgcolor: "#f8f9fa", minHeight: "100vh", p: 2 }}>
      <Paper
        elevation={1}
        sx={{
          bgcolor: "#fff",
          borderRadius: 2,
          overflow: "hidden",
        }}
      >
        {/* Header */}
        <Box
          sx={{
            bgcolor: "#1a1a2e",
            p: 2,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton 
            sx={{ color: "#fff" }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" sx={{ color: "#fff" }}>
            Questions
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: 2,
            }}
          >
            <Select
              value={filterType}
              onChange={handleFilterChange}
              size="small"
              sx={{
                color: "#fff",
                minWidth: "120px",
                height: "32px",
                '.MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.5)',
                },
                '&:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.8)',
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                  borderColor: 'rgba(255, 255, 255, 0.8)',
                },
                '.MuiSvgIcon-root': {
                  color: '#fff',
                },
              }}
              MenuProps={{
                PaperProps: {
                  sx: {
                    bgcolor: '#1a1a2e',
                  },
                },
              }}
            >
              <MenuItem 
                value="all"
                sx={{ 
                  color: '#000',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                  '&.Mui-selected': { bgcolor: 'rgba(255, 255, 255, 0.2)' },
                }}
              >
                All
              </MenuItem>
              <MenuItem 
                value="attempted"
                sx={{ 
                  color: '#000',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                  '&.Mui-selected': { bgcolor: 'rgba(255, 255, 255, 0.2)' },
                }}
              >
                Attempted
              </MenuItem>
              <MenuItem 
                value="unattempted"
                sx={{ 
                  color: '#000',
                  '&:hover': { bgcolor: 'rgba(255, 255, 255, 0.1)' },
                  '&.Mui-selected': { bgcolor: 'rgba(255, 255, 255, 0.2)' },
                }}
              >
                Unattempted
              </MenuItem>
            </Select>
            <Typography sx={{ color: "#fff", width: "100px" }}>
              {getFilteredQuestions().length} Qs
            </Typography>
          </Box>
        </Box>

        {/* Questions List */}
        <Box sx={{ p: 2 }}>
          {getCurrentPageQuestions().map((question, index) => (
            <QuestionCard
              key={question.id}
              number={(currentPage - 1) * ITEMS_PER_PAGE + index + 1}
              onNewClick={handleNewClick}
              {...question}
            />
          ))}
        </Box>

        {/* Pagination */}
        <Box
          sx={{
            p: 2,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
            bgcolor: '#1a1a2e', 
          }}
        >
          <Pagination
            totalPages={Math.ceil(getFilteredQuestions().length / ITEMS_PER_PAGE)}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        </Box>
      </Paper>
    </Box>
  );
}

export default Questions;