import { useState, useRef, useEffect } from "react";
import { Box, IconButton, Button } from "@mui/material";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeDownIcon from '@mui/icons-material/VolumeDown';

const AudioPlayer = ({ audioText }) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [volume, setVolume] = useState(1);
  const [isHoveringVolume, setIsHoveringVolume] = useState(false);

  const speechRef = useRef(null);
  const progressIntervalRef = useRef(null);
  const [duration, setDuration] = useState(0);  // Store the speech duration

  useEffect(() => {
    if (!audioText) return;

    const speech = new SpeechSynthesisUtterance(audioText);
    speechRef.current = speech;

    // Set the volume and rate
    speech.volume = volume;
    speech.rate = playbackRate;

    // Event listener for progress tracking
    speech.onstart = () => {
      setIsPlaying(true);
      setDuration(speech.text.length * 100);  // Estimated duration (based on text length)
      speechSynthesis.speak(speech);
      startProgressTracking(speech);
    };

    speech.onend = () => {
      setIsPlaying(false);
      setProgress(0);
      clearInterval(progressIntervalRef.current);
    };

    return () => {
      clearInterval(progressIntervalRef.current);
    };
  }, [audioText, volume, playbackRate]);

  // Start progress tracking based on the estimated time of speech
  const startProgressTracking = (speech) => {
    const estimatedDuration = Math.max(
      speech.text.length * 100, // Approximate 100ms per character
      3000 // Fallback minimum duration
    );

    let startTime = Date.now(); // Record the start time of the speech
    progressIntervalRef.current = setInterval(() => {
      const elapsedTime = Date.now() - startTime; // Calculate the elapsed time
      const progressPercentage = Math.min((elapsedTime / estimatedDuration) * 100, 100);
      setProgress(progressPercentage);

      if (elapsedTime >= estimatedDuration) {
        clearInterval(progressIntervalRef.current);
      }
    }, 50); // Update every 50ms
  };

  // Toggle play/pause for speech
  const togglePlay = () => {
    const speech = speechRef.current;
    if (isPlaying) {
      speechSynthesis.cancel(); // Stop if it's playing
      setIsPlaying(false);
    } else {
      speechSynthesis.speak(speech); // Start speaking the text
      setIsPlaying(true);
    }
  };

  // Handle playback rate change (speed)
  const handlePlaybackRate = () => {
    const newRate = playbackRate >= 2 ? 0.5 : playbackRate + 0.5;
    setPlaybackRate(newRate);
    const speech = speechRef.current;
    speech.rate = newRate;
    // Update the speech rate during playback without interrupting
    speechSynthesis.speak(speech); // Re-speak with updated rate
  };

  // Handle volume change
  const handleVolumeChange = (event) => {
    const newVolume = parseFloat(event.target.value);
    setVolume(newVolume);
    const speech = speechRef.current;
    speech.volume = newVolume;
    // Update the volume during playback without interrupting
    speechSynthesis.speak(speech); // Re-speak with updated volume
  };

  const getVolumeIcon = () => {
    if (volume === 0) return <VolumeOffIcon />;
    if (volume < 0.5) return <VolumeDownIcon />;
    return <VolumeUpIcon />;
  };

  return (
    <Box
      sx={{
        bgcolor: "#fff",
        borderRadius: 1,
        p: 1.5,
        display: "flex",
        alignItems: "center",
        gap: 2,
        mb: 3,
      }}
    >
      <IconButton onClick={togglePlay} size="small" sx={{ color: "#333" }}>
        <i className={`fas fa-${isPlaying ? "pause" : "play"}`} />
      </IconButton>

      <Box
        sx={{
          flexGrow: 1,
          height: "4px",
          bgcolor: "#E9ECEF",
          borderRadius: "2px",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: `${progress}%`,
            bgcolor: "#00BFA6",
            borderRadius: "2px",
          }}
        />
      </Box>

      <Button
        size="small"
        onClick={handlePlaybackRate}
        sx={{
          color: "#666",
          minWidth: "auto",
        }}
      >
        {playbackRate}x
      </Button>

      <Box
        sx={{
          position: 'relative',
          display: 'flex',
          alignItems: 'center',
        }}
        onMouseEnter={() => setIsHoveringVolume(true)}
        onMouseLeave={() => setIsHoveringVolume(false)}
      >
        <IconButton
          size="small"
          sx={{ color: "#333" }}
          onClick={() => setVolume(volume === 0 ? 1 : 0)}
        >
          {getVolumeIcon()}
        </IconButton>

        {isHoveringVolume && (
          <Box
            sx={{
              position: 'absolute',
              bottom: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              width: '24px',
              height: '100px',
              backgroundColor: '#fff',
              boxShadow: '0 0 10px rgba(0,0,0,0.1)',
              borderRadius: '4px',
              padding: '8px 0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={handleVolumeChange}
              style={{
                width: '80px',
                writingMode: 'bt-lr',
                transform: 'rotate(-90deg)',
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AudioPlayer;
